import React from "react"
import Layout from "../template/index"
import SEO from "../utils/seo"
import styled from "styled-components"
import Boxed from "../bits/box"
import Img from "gatsby-image"
import { graphql } from "gatsby"

const Row = styled.section`
  padding: var(--var-padding-m) 16px;
  background: white;
  display: -ms-flexbox; /* IE10 */
  display: flex;
  -ms-flex-wrap: wrap; /* IE10 */
  flex-wrap: wrap;
`

const Partners = styled(Boxed)`
  max-width: var(--page-container-l);
`

const Column = styled.div`
  -ms-flex: 50%; /* IE10 */
  flex: 50%;
  max-width: 50%;
  padding: 0 4px;

  @media screen and (max-width: 800px) {
    -ms-flex: 50%;
    flex: 50%;
    max-width: 50%;
  }
  @media screen and (max-width: 600px) {
    -ms-flex: 100%;
    flex: 100%;
    max-width: 100%;
  }
`

const ColumnSmall = styled.div`
  -ms-flex: 25%; /* IE10 */
  flex: 25%;
  max-width: 25%;
  padding: 0 4px;

  @media screen and (max-width: 800px) {
    -ms-flex: 50%;
    flex: 50%;
    max-width: 50%;
  }
  @media screen and (max-width: 600px) {
    -ms-flex: 100%;
    flex: 100%;
    max-width: 100%;
  }
`

const Image = styled(Img)``

const ImageContainer = styled.div`
  height: 100%;
  margin: 10px;
  padding: 15px;
  align-items: center;
  justify-content: center;
  opacity: 0.5;
  -webkit-filter: grayscale(80%); /* Safari 6.0 - 9.0 */
  filter: grayscale(80%);

  &:hover {
    //box-shadow: 5px 5px 5px 5px #f1f1f1;
    opacity: 1;
    -webkit-filter: grayscale(0%); /* Safari 6.0 - 9.0 */
    filter: grayscale(0%);
  }

  @media only screen and (max-width: 1024px) {
    -webkit-filter: grayscale(0%); /* Safari 6.0 - 9.0 */
    filter: grayscale(0%);
    opacity: 1;
  }
`

const Partnerships = ({ data }) => (
  <Layout>
    <SEO title="Partnerships" />

    <Partners>
      <h3>VC Partners</h3>
      <Row>
        <ColumnSmall>
          <ImageContainer>
            <Image
              fluid={data.centrality.childImageSharp.fluid}
              alt="Centrality"
            />
          </ImageContainer>
        </ColumnSmall>
        <ColumnSmall>
          <ImageContainer>
            <Image
              fluid={data.cosimo.childImageSharp.fluid}
              alt="Cosimo Ventures"
            />
          </ImageContainer>
        </ColumnSmall>
      </Row>

      <h3>Professional Memberships</h3>
      <Row>
        <ColumnSmall>
          <ImageContainer>
            <Image fluid={data.bpfi.childImageSharp.fluid} alt="BPFI" />
          </ImageContainer>
        </ColumnSmall>
        <ColumnSmall>
          <ImageContainer>
            <Image fluid={data.fpai.childImageSharp.fluid} alt="FPAI" />
          </ImageContainer>
        </ColumnSmall>

        <ColumnSmall>
          <ImageContainer>
            <Image
              fluid={data.tfc.childImageSharp.fluid}
              alt="Dundalk Chambers"
            />
          </ImageContainer>
        </ColumnSmall>
        <ColumnSmall>
          <ImageContainer>
            <Image
              fluid={data.iibn.childImageSharp.fluid}
              alt="IIBN"
              style={{}}
            />
          </ImageContainer>
        </ColumnSmall>

        <ColumnSmall>
          <ImageContainer>
            <Image
              fluid={data.dundalkChambers.childImageSharp.fluid}
              alt="Dundalk Chambers"
            />
          </ImageContainer>
        </ColumnSmall>
        <ColumnSmall>
          <ImageContainer>
            <Image fluid={data.dkit.childImageSharp.fluid} alt="DKIT" />
          </ImageContainer>
        </ColumnSmall>

        <ColumnSmall>
          <ImageContainer>
            <Image fluid={data.irta.childImageSharp.fluid} alt="IRTA" />
          </ImageContainer>
        </ColumnSmall>
      </Row>

      <h3>Industry Partners</h3>
      <Row>
        <ColumnSmall>
          <ImageContainer>
            <Image fluid={data.aws.childImageSharp.fluid} alt="AWS" />
          </ImageContainer>
        </ColumnSmall>

        <ColumnSmall>
          <ImageContainer>
            <Image
              fluid={data.centralityFintech.childImageSharp.fluid}
              alt="Centality Fintech"
            />
          </ImageContainer>
        </ColumnSmall>
      </Row>

      <h3>Accreditation</h3>
      <Row>
        <ColumnSmall>
            <ImageContainer>
              <Image fluid={data.iso.childImageSharp.fluid} alt="ISO27001" />
            </ImageContainer>
        </ColumnSmall>
        <ColumnSmall>
            <ImageContainer>
              <Image fluid={data.crest.childImageSharp.fluid} alt="CREST" />
            </ImageContainer>
        </ColumnSmall>
        
      </Row>
    </Partners>
  </Layout>
)

export default Partnerships

export const pageQuery = graphql`
  query {
    centrality: file(relativePath: { eq: "Centrality.png" }) {
      childImageSharp {
        fluid(maxHeight: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    cosimo: file(relativePath: { eq: "Cosimo_Ventures.png" }) {
      childImageSharp {
        fluid(maxHeight: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    bpfi: file(relativePath: { eq: "BPFI.png" }) {
      childImageSharp {
        fluid(maxHeight: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    dkit: file(relativePath: { eq: "DKIT.png" }) {
      childImageSharp {
        fluid(maxHeight: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    dundalkChambers: file(relativePath: { eq: "DundalkChamber.png" }) {
      childImageSharp {
        fluid(maxHeight: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    fpai: file(relativePath: { eq: "FPAI.png" }) {
      childImageSharp {
        fluid(maxHeight: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    iibn: file(relativePath: { eq: "IIBN.png" }) {
      childImageSharp {
        fluid(maxHeight: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    irta: file(relativePath: { eq: "IRTA.png" }) {
      childImageSharp {
        fluid(maxHeight: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    tfc: file(relativePath: { eq: "TFC.png" }) {
      childImageSharp {
        fluid(maxHeight: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    aws: file(relativePath: { eq: "AWS.png" }) {
      childImageSharp {
        fluid(maxHeight: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    centralityFintech: file(relativePath: { eq: "CentralityFintech.png" }) {
      childImageSharp {
        fluid(maxHeight: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    fd: file(relativePath: { eq: "FD.png" }) {
      childImageSharp {
        fluid(maxHeight: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    crest: file(relativePath: { eq: "crest.png" }) {
      childImageSharp {
        fluid(maxHeight: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    iso: file(relativePath: { eq: "ISO27001.png" }) {
      childImageSharp {
        fluid(maxHeight: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
